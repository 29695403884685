export const getJson = (url) => {
  return new Promise((resolve, reject) => {
    const XHR = new XMLHttpRequest();
    let http = 'http://freesaasofficedata.oss.aliyuncs.com';
    let httpUrl = http + url;
    XHR.open('GET', httpUrl, true);
    // 设置请求头类型
    XHR.setRequestHeader("Content-type", "application/json");
    XHR.onreadystatechange = function () {
      if (XHR.readyState === 4) {
        if (XHR.status === 200) {
          resolve(XHR.response)
        } else {
          reject(XHR.status)
        }
      }
    };
    XHR.send();
  })
};