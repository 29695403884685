<template>
  <div class="container">
    <div class="ivv-title-box">
      <div class="ivv-item" :class="{ 'active': active === index }" v-for="(item,index) in navList" :key="index">
        <span class="num">{{ index + 1 }}</span>
        <p>{{ item }}</p>
      </div>
    </div>
    <div class="ivv-content">
      <div class="apply-content" v-if="active === 0">
        <van-cell-group>
          <van-cell title="品类" :value="brandId === 4 ? '大健康' : '美妆'" />
          <van-field :value="agentInfo ? agentInfo.agent_name : ''" label="姓名" placeholder="填写真实姓名" disabled />
          <van-field class="phone-area" :value="agentInfo ? agentInfo.agent_pho : ''" disabled label="手机号"
            placeholder="填写手机号码">
            <template #button>
              <van-button size="small" @click="showPhoneAreaCode = true">
                +{{ cityTelCode }}
                <van-icon name="arrow" />
              </van-button>
            </template>
          </van-field>
          <van-field v-model="verifyCode" label="验证码" placeholder="填写短信验证码">
            <template #button>
              <van-button size="small" type="primary" color="#FF98A0" @click="getValidCode">
                {{ getValidBtnText }}
              </van-button>
            </template>
          </van-field>
          <van-cell class="valid-type" v-if="isHasSendValidCode">
            <div class="tip" v-if="validBtnType === 1">验证码已发送到您的手机
              <span>+{{ cityTelCode }} {{ agentInfo.agent_pho }}</span>
            </div>
            <div class="tip" v-else>我们会以电话的方式告知您验证码,请注意接听</div>
            <div class="btn" @click="switchValidType">{{ validBtnType === 1 ? '使用语音验证' : '返回短信验证' }}<span
                class="icon-arrow-right"></span></div>
          </van-cell>
        </van-cell-group>
        <van-cell-group>
          <van-cell title="提现到" />
          <van-radio-group v-model="radio">
            <van-cell title="微信零钱包" clickable @click="radio = '1'">
              <template #icon>
                <van-icon name="wechat-pay" color="#4FCB78" />
              </template>
              <template #right-icon>
                <van-radio name="1" checked checked-color="#FF98A0" />
              </template>
            </van-cell>
            <van-field class=" ali-count" v-model="wxPayAccount" placeholder="填写与微信钱包账户一致的姓名" v-show="radio === '1'">
              <template slot="button">
                <span @click="showWxPayLayer = true">如何查看姓名</span>
                <van-icon name="question" @click.native="showWxPayLayer = true" />
              </template>
            </van-field>
            <div class="cus-popover" v-show="showWxPayLayer">
              <p>查看实名身份信息位置在：</p>
              <p>微信--我--服务--钱包--身份信息--实名认证状态</p>
            </div>
            <van-cell clickable @click="radio = '2'">
              <template #title>
                <span>支付宝余额</span>
                <i v-if="agentInfo && agentInfo.agent_name">（支付宝认证姓名：{{ agentInfo.agent_name }}）</i>
              </template>
              <template #icon="props">
                <van-icon name="alipay" color="#53B3F0" />
              </template>
              <template #right-icon>
                <van-radio name="2" checked-color="#FF98A0" />
              </template>
            </van-cell>
          </van-radio-group>
          <template v-if="radio === '2' && !historyAliCount">
            <van-field class="pad-left ali-count" v-model="aliCount" placeholder="输入支付宝账号">
              <template slot="button">如何查看账号
                <van-icon name="question" @click="showAliPayLayer = true" />
              </template>
            </van-field>
            <van-field class="pad-left" v-model="reAliCount" placeholder="再输入一次确认" />
          </template>
          <template v-if="radio === '2' && historyAliCount">
            <div style="display: flex;margin-left: 50px;justify-content: space-between;box-sizing: border-box;padding-right: 20px;
padding-bottom: 30px;position: relative;">
              <div><span style="color: #999">账号：</span><span>{{ historyAliCount }}</span></div>
              <span
                style="display: inline-block;padding: 1px 8px;border-radius: 500px;border: 1px solid #FFB5BB;color: #FFB5BB"
                @click="changeAliCount">修改</span>
              <div class="line" style="position: absolute;bottom: 20px;width: 95%;height: 1px;background-color: #eee">
              </div>
            </div>
          </template>
        </van-cell-group>
        <van-button class="ivv-btn-submit" block color="#FF98A0" @click="submitApply">提交申请</van-button>
      </div>
      <div class="auth-info" v-else-if="active === 1">
        <img :src="approvalList[approvalIndex].imagePath" />
        <div class="title">{{ approvalList[approvalIndex].title }}</div>
        <p v-html="approvalList[approvalIndex].describe" />
        <van-button class="ivv-btn-progress" block color="#FF98A0"
          v-if="approvalIndex === 1 || approvalIndex === 3 || approvalIndex === 4" @click="viewRefundFlow">退保证金进度
        </van-button>
      </div>
      <div class="refund-info" v-else>
        <van-cell-group>
          <van-cell class="theme-title" title="保证金退款信息" clickable @click="switchBrand"
            :value="brandId === 4 ? '大健康' : '美妆'" />
          <!--                    <van-cell class="theme-title" title="保证金退款信息" clickable/>-->
          <van-cell title="店主姓名：" :value="applyInfo ? applyInfo.AgentName : ''" />
          <van-cell title="身份证号：" :value="applyInfo ? applyInfo.AgentIdCard : ''" />
          <van-cell title="店主身份：" :value="applyInfo ? applyInfo.AgentLevelName : ''" />
          <van-cell title="授权状态：" :value="applyInfo ? applyInfo.AuthState : ''" />
          <!--                    <van-cell title="缴纳保证金金额：" :value="applyInfo ? applyInfo.Money:''"/>-->
          <!--                    <van-cell title="缴纳时间：" :value="applyInfo ? applyInfo.BondOldestDate:''"/>-->
        </van-cell-group>
        <div class="step_box">
          <div v-for="(item, idx) in refundList" :key="idx" class="step_item">
            <div class="step_item_circle"></div>
            <div class="step_content">
              <img class="step_icon" src="http://freesaasofficedata.oss.aliyuncs.com/miShop/20210625144218004.png"
                alt="">
              <div class="title">{{ item.flowName }}</div>
              <p>办理结果：{{ item.result }}</p>
              <p v-if="item.money">{{ item.flowName == '公司审核' ? '待' : '' }}退款金额：<span style="color: #FFB5BB">{{
                  item.money
              }}元</span></p>
              <p>办理时间：{{ item.dateTime }} </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <van-popup class="alipay-layer" v-model="showAliPayLayer">
      <van-icon name="clear" @click="showAliPayLayer = false" />
      <img src="http://freesaasofficedata.oss.aliyuncs.com/miShop/20201106162310008.png" />
    </van-popup>
    <van-popup v-model="showPhoneAreaCode" position="bottom">
      <van-picker show-toolbar title="请选择区号" :columns="cityTel" @cancel="showPhoneAreaCode = false"
        @confirm="selectCityTel"></van-picker>
    </van-popup>
    <!--    图文验证-->
    <div v-if="showVerify" @click="showVerify = false" class="verify_contianer">
      <div @click.stop class="verify_box">
        <div class="verify_title">请依次点击图片中的“{{ verifyJson.verifyWords }}”完成验证</div>
        <div class="verify_view">
          <div style="position: absolute;width: 282px;height: 216px;left: 9px;top: 9px">
            <img @click="verifyFocus" class="verify_img" :src="verifyJson.base64" alt="">
            <div class="verify_circle" v-if="item.show" v-for="(item, index) in events"
              :style="`left:${item.x}px;top:${item.y}px`">{{ index + 1 }}
            </div>
            <!--                    <div class="verify_circle" style="left:20px;top:20px">1</div>-->
          </div>
        </div>
        <div
          style="width: 100%;display: flex;justify-content: space-between;align-items: center;margin-top: 10px;box-sizing: border-box;padding: 0 10px">
          <div @click="refresh" style="display: flex;align-items: center;color: #999">
            <img style="width: 20px;height: 20px"
              src="http://freesaasofficedata.oss.aliyuncs.com/miShop/20210720155136207.png" alt="">
            <div style="margin-left: 5px">刷新</div>
          </div>
          <div @click="verifyConfirm"
            style="width: 100px;height: 30px;background-color: rgb(255,181,187);display: flex;align-items: center;justify-content: center;border-radius: 500px;color: #fff">
            确定
          </div>
        </div>
      </div>
    </div>
    <van-overlay :show="showWxPayLayer" @click="showWxPayLayer = false" style="background-color: rgba(0,0,0,0);" />
  </div>
</template>

<script>
import { trim, regTel } from "../../utils/public"
import { getJson } from "../../utils/xhr"
export default {
  name: "RefundDeposit",
  data() {
    return {
      active: 0,
      brandId: 3,
      agentInfo: null,
      navList: ['填写申请', '公司审核', '退保证金'],
      verifyCode: '',
      verifyCodeId: '',
      radio: '2',
      aliCount: '',
      wxPayAccount: '',
      reAliCount: '',
      historyAliCount: '',//支付宝历史账号
      cityTelCode: '86',
      endTime: 60,
      cityTel: [],
      showAliPayLayer: false,
      isHasSendValidCode: false,
      validBtnType: 1,  //1短信验证 2语音验证
      validList: [
        { id: 1, validBtnText: '获取验证码', sendBtnText: 's后重新发送', lastNum: 0 },
        { id: 2, validBtnText: '重新回拨', sendBtnText: '回拨中', lastNum: 0 }
      ],
      showPhoneAreaCode: false, //是否显示国家弹出框
      approvalIndex: 0,
      approvalList: [
        {
          title: '审核中...', describe: '已收到您提交的退保证金申请，我们将尽快为您处理，请留意消息',
          imagePath: 'http://freesaasofficedata.oss.aliyuncs.com/miShop/20210607173958324.png'
        },
        {
          title: '审核通过!',
          describe: '您提交的退保证金已通过审核，我们将尽快处理，请在此期间不要更改收款账户，以确保顺利收款。<br/>请点击下面按钮查看“退保证金进度”',
          // describe: '',
          imagePath: 'http://freesaasofficedata.oss.aliyuncs.com/miShop/20210607174024304.png'
        },
        {
          title: '审核未通过!', describe: '您提交的退保证金未通过审核，您可联系客服处理',
          imagePath: 'http://freesaasofficedata.oss.aliyuncs.com/miShop/20210607174033925.png'
        },
        {
          title: '退款成功!',
          // describe: '您提交的退保证金已通过审核，我们将在3个月后支付到您申请的账号中，请在此期间不要更改收款账户，以确保顺利收款。<br/>请点击下面按钮查看“退保证金进度”',
          describe: '',
          imagePath: 'http://freesaasofficedata.oss.aliyuncs.com/miShop/20210607174024304.png'
        },
        {
          title: '退款失败!', describe: '',
          imagePath: 'http://freesaasofficedata.oss.aliyuncs.com/miShop/20210607174033925.png'
        },
      ],
      applyInfo: null, //申请信息
      refundList: [], //退款流程
      type: 0,
      verify: false,//是否通过了图文验证
      showVerify: false,
      guid: '',
      verifyJson: {},
      events: [{ x: 0, y: 0, show: false }, { x: 0, y: 0, show: false }, { x: 0, y: 0, show: false }, {
        x: 0,
        y: 0,
        show: false
      }],
      showWxPayLayer: false
    }
  },
  created() {
    let { brandId } = this.$route.query;
    if (brandId) this.brandId = +brandId;
    this.type = this.$route.query.type
    this.countDown(this.validBtnType);
    this.getRefundFlowInfo();
    this.getWithdrawalInfo()
    this.getVerificationCode()
  },
  methods: {
    getWithdrawalInfo: function () {
      this.get('/PersonCenter/Balance/GetWithdrawalInfo', { source: 2 }, 2).then(data => {
        let { realName, aliAccount } = data.response;
        this.historyAliCount = aliAccount
        this.aliCount = aliAccount
        this.reAliCount = aliAccount
        this.wxPayAccount = realName
      })
    },
    changeAliCount: function () {
      this.historyAliCount = ''
      this.aliCount = ''
      this.reAliCount = ''
    },
    //获取退保证金流程信息
    getRefundFlowInfo: function () {
      this.$toast.loading({ message: '加载中...', duration: 0, overlay: true });
      this.get("/PersonCenter/TeamAgent/IsApply", {
        brandId: this.brandId
      }, 2).then(json => {
        if (json.code === 1) {
          let { state } = json.response;
          this.getAgentInfo();
          if (state <= 0) {
            this.getAddressList();
            this.getAgentInfo();
          } else if (state === 40) {
            this.active = 1;
            this.getApplyRefundInfo(this.brandId);
            this.approvalIndex = 1
          } else if (state == 50) {
            this.active = 1;
            this.getApplyRefundInfo(this.brandId);
            this.approvalIndex = 3
          } else if (state == 60) {
            this.active = 1;
            this.getApplyRefundInfo(this.brandId);
            this.approvalIndex = 4
          } else {
            if (state === 10) this.approvalIndex = 0;
            else if ([20, 30].indexOf(state) >= 0) {
              this.approvalIndex = 1;
            } else {
              this.approvalIndex = 2;
            }
            this.active = 1;
            this.$toast.clear();
          }
          if (this.type) this.active = 0
        }
      });
    },
    //获取代理信息
    getAgentInfo: function () {
      this.get("/PersonCenter/TeamAgent/GetAgentInfoByBrandId?", {
        brandId: this.brandId
      }, 2).then(json => {
        this.$toast.clear();
        if (json.code === 1) {
          this.agentInfo = json.response;
          // console.log(this.agentInfo)
        } else {
          this.$toast(json.msg);
        }
      })
    },
    //获取地址
    getAddressList: function () {
      getJson("/staticSourceTemp/FixedFile/city.data-export.json").then(res => {
        let json = JSON.parse(res);
        this.cityTel = json.cityTel;
      });
    },
    //获取退保证金信息
    getApplyRefundInfo: function (bid) {
      this.get("/PersonCenter/TeamAgent/GetApplyDetail?", {
        brandId: bid
      }, 2).then(json => {
        this.$toast.clear();
        if (json.code === 1) {
          this.applyInfo = json.response.detail;
          this.refundList = json.response.fllow;
          this.brandId = bid;
        } else {
          this.$dialog.alert({ title: '错误', message: json.msg });
        }
      });
    },
    //获取验证码
    getValidCode: function () {
      let obj = this.validList[this.validBtnType - 1];
      if (obj.lastNum > 0) return;
      if (!this.agentInfo) return;
      let tel = trim(this.agentInfo.agent_pho);
      if (!tel) return this.$dialog.alert({ title: "提示", message: '手机号码不能为空!' });
      if (this.cityTelCode === "86" && !regTel(tel)) return this.$dialog.alert({
        title: "提示",
        message: '手机号格式不正确!'
      });
      if (this.validBtnType === 2 && !this.verify) {
        //未经过图文验证
        return this.showVerify = true
      } else {
        let language = this.cityTelCode === "86" ? 1 : 2;
        //   /CommAPI/Comm/SendPhoneCode_V2
        let url = this.validBtnType === 2 ? '/CommAPI/Comm/SendVoiceCode?' : '/CommAPI/Comm/SendPhoneCode_V2?';
        this.$toast.loading({ message: '正在发送...', duration: 0, mask: true });
        this.post(url, {
          phone: tel,
          type: 1,
          nationCode: this.cityTelCode
        }, true, 2).then(json => {
          this.$toast.clear();
          if (json.code === 1) {
            this.verifyCodeId = json.response.codeID;
            let storage = window.localStorage;
            let obj = JSON.parse(storage.getItem(tel)) || {};
            let count = obj.count || 0;
            obj.count = count + 1;
            storage.setItem(tel, JSON.stringify(obj));
            let keyword = "lastTime" + this.validBtnType;
            window.localStorage[keyword] = (+new Date());
            let message = this.validBtnType === 2 ? "我们会以电话的方式告知您验证码,请注意接听!" : "验证码已发送至手机,请注意查收!";
            this.$dialog.alert({ title: "提示", message: message });
          } else {
            this.$dialog.alert({ title: "提示", message: json.msg });
          }
          this.sendCodeBack(json);
        });
      }
    },
    sendCodeBack: function (json) {
      if (json.code === 1) {
        this.isHasSendValidCode = true;
        this.countDown(this.validBtnType);
      }
    },
    //验证码获取倒计时
    countDown: function (typeId) {
      let obj = this.validList[typeId - 1];
      let last_time = window.localStorage['lastTime' + typeId];
      let time_distance = (+new Date()) - last_time;
      /* 时间差：活动结束时间减去当前时间  */
      let int_second = Math.floor(time_distance / 1000);
      let downTime = this.endTime - int_second;
      if (downTime > 0) {
        setTimeout(() => {
          this.countDown(typeId);
        }, 1000);
        obj.lastNum = downTime;
      } else {
        obj.lastNum = 0;
        window.localStorage.removeItem("lastTime");
      }
    },
    //切换短信验证码的类型
    switchValidType: function () {
      this.validBtnType = this.validBtnType === 1 ? 2 : 1;
    },
    //选择手机区号
    selectCityTel: function (item) {
      this.cityTelCode = item.value;
      this.showPhoneAreaCode = false;
    },
    //查看退保证金流程
    viewRefundFlow: function () {
      this.active = 2;
      this.$toast.loading({ message: '加载中...', duration: 0, overlay: true });
      this.getApplyRefundInfo(this.brandId);
    },
    //切换品牌
    switchBrand: function () {
      return
      let bid = this.brandId === 4 ? 3 : 4;
      this.$toast.loading({ message: '加载中...', duration: 0, overlay: true });
      this.getApplyRefundInfo(bid);
    },
    //提交申请
    submitApply: function () {
      if (!this.agentInfo) return;
      if (!this.verifyCode) return this.$toast("请填写短信验证码");
      if (!this.radio) return this.$toast("请选择提现方式!");
      let accountName = "微信";
      if (this.radio === '2') {
        accountName = "支付宝";
        if (!this.aliCount) return this.$toast("请输入您的支付宝账号!");
        if (!this.reAliCount) return this.$toast("请重复输入您的支付宝账号!");
        if (this.aliCount !== this.reAliCount) return this.$toast("您的支付宝账号输入不一致!");
      }
      else if (!this.wxPayAccount) return this.$toast("请输入与微信钱包账户一致的姓名!");
      this.$dialog.confirm({ title: '提示', message: `申请成功后钱直接到您的${accountName}账户中，为确保款项入账无误，请确认您的${accountName}账户正确！` }).then(() => {
        let { id, agent_name, agent_id_card, agent_pho, level_code } = this.agentInfo;
        this.$toast.loading({ message: '申请提交中...', duration: 0, overlay: true });
        this.post("/PersonCenter/TeamAgent/AddReFoundApplyV2", {
          BrandId: this.brandId,
          AgentId: id,
          AgentName: agent_name,
          AgentIdCard: agent_id_card,
          AgentPhone: agent_pho,
          AuthCode: level_code,
          VerificationCode: this.verifyCode,
          RebackType: this.radio === '1' ? 10 : 20,
          UserAccount: this.aliCount,
          UserAccountRepe: this.reAliCount,
          realName: this.wxPayAccount,
          // UserUnionId: '',
          CompanyId: 7999
          //  CodeId: this.verifyCodeId
        }, true, 2).then(json => {
          this.$toast.clear();
          if (json.code === 1) {
            this.$dialog.alert({ title: '提示', message: '申请成功!' }).then(() => {
              this.approvalIndex = 0;
              this.active = 1;
            })
          } else {
            this.$dialog.alert({ title: '提示', message: json.msg });
          }
        })
      })
    },
    //点击图文生成小球
    verifyFocus: function (e) {
      for (var i = 0; i < this.events.length; i++) {
        if (!this.events[i].show) {
          this.events[i].show = true
          this.events[i].x = e.offsetX - 11
          this.events[i].y = e.offsetY - 11
          break;
        }
      }
    },
    //刷新图文验证
    refresh: function () {
      this.getVerificationCode()
      this.events = [{ x: 0, y: 0, show: false }, { x: 0, y: 0, show: false }, { x: 0, y: 0, show: false }, {
        x: 0,
        y: 0,
        show: false
      }]
    },
    //图文验证提交
    verifyConfirm: function () {
      var that = this
      var events = this.events
      var arr = []
      for (var i = 0; i < this.events.length; i++) {
        if (this.events[i].show) {
          arr.push({
            xPoint: parseInt(this.events[i].x / 282 * 460),
            yPoint: parseInt(this.events[i].y / 216 * 340),
          })
        }
      }
      if (arr.length != 4) {
        this.$toast.fail({
          message: '验证失败，请重试'
        })
        setTimeout(() => {
          this.refresh()
        }, 1000)
      } else {
        arr = JSON.stringify(arr)
        that.get(`/CommAPI/Comm/VerificationImgCode?id=${that.verifyJson.id}&pointData=${arr}`, {}, 2).then(json => {
          if (json.code == 1) {
            that.events = [{ x: 0, y: 0, show: false }, { x: 0, y: 0, show: false }, { x: 0, y: 0, show: false }, {
              x: 0,
              y: 0,
              show: false
            }]
            that.showVerify = false
            that.refresh()
            let language = this.cityTelCode === "86" ? 1 : 2;
            //   /CommAPI/Comm/SendPhoneCode_V2
            let url = that.validBtnType === 2 ? '/CommAPI/Comm/SendVoiceCode?' : '/CommAPI/Comm/SendPhoneCode_V2?';
            let tel = trim(this.agentInfo.agent_pho);
            that.$toast.loading({ message: '正在发送...', duration: 0, mask: true });
            that.post(url, {
              phone: tel,
              type: 1,
              nationCode: language
            }, true, 2).then(json => {
              that.$toast.clear();
              if (json.code === 1) {
                that.verifyCodeId = json.response.codeID;
                let storage = window.localStorage;
                let obj = JSON.parse(storage.getItem(tel)) || {};
                let count = obj.count || 0;
                obj.count = count + 1;
                storage.setItem(tel, JSON.stringify(obj));
                let keyword = "lastTime" + that.validBtnType;
                window.localStorage[keyword] = (+new Date());
                let message = that.validBtnType === 2 ? "我们会以电话的方式告知您验证码,请注意接听!" : "验证码已发送至手机,请注意查收!";
                that.$dialog.alert({ title: "提示", message: message });
              } else {
                that.$dialog.alert({ title: "提示", message: json.msg });
              }
              that.sendCodeBack(json);
            });
          } else {
            that.$dialog.alert({
              message: json.msg
            }).then(() => {
              that.refresh()
            })
          }
        });
      }
    },
    //获取图文验证相关信息
    getVerificationCode: function () {
      this.$toast.loading({ duration: 10000 })
      var S4 = function () {
        return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
      }
      var guid = function () {
        return (S4() + S4() + "-" + S4() + "-" + S4() + "-" + S4() + "-" + S4() + S4() + S4());
      }
      this.guid = guid()
      var that = this
      this.get("/CommAPI/Comm/GetVerificationCode", { guid: this.guid }, 2, this.guid).then(json => {
        that.$toast.clear()
        if (json && json.code == 1) {
          that.verifyJson = json.response
          that.verifyJson.verifyWords = that.verifyJson.str[0] + that.verifyJson.str[1] + that.verifyJson.str[2] + that.verifyJson.str[3]
        }
      })
    },
  },
  computed: {
    getValidBtnText: function () {
      let id = this.validBtnType;
      let str = "";
      let obj = this.validList[id - 1];
      if (id === obj.id) {
        if (obj.lastNum <= 0) {
          str = obj.validBtnText;
        } else {
          str = id === 1 ? (obj.lastNum + obj.sendBtnText) : (obj.sendBtnText + obj.lastNum + "s");
        }
      }
      return str;
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  .verify_contianer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
  }

  .verify_box {
    width: 85%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    border-radius: 15px;
    background-color: #fff;
    height: 344px;
    box-sizing: border-box;
    padding-top: 20px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .verify_title {
    color: #999;
    font-size: 14px;
    text-align: center;
  }

  .verify_view {
    height: 234px;
    width: 300px;
    box-sizing: border-box;
    padding: 8px;
    margin-top: 8px;
    border: 1px solid #eee;
    position: relative;
  }

  .verify_circle {
    position: absolute;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    box-sizing: border-box;
    border: 3px solid #fff;
    background-color: rgb(255, 181, 187);
    color: #fff;
    z-index: 99;
    display: flex;
    align-content: center;
    justify-content: center;
    line-height: 14px;
  }

  .verify_img {
    width: 100%;
    height: 100%;
  }

  .ivv-title-box {
    padding: 15px 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .ivv-item {
      width: 33%;
      text-align: center;
      position: relative;

      .num {
        width: 22px;
        height: 22px;
        line-height: 22px;
        display: inline-block;
        border-radius: 50%;
        background-color: #f1f1f1;
      }

      p {
        color: #666;
        margin-top: 10px;
      }

      &.active {
        .num {
          color: #fff;
          background-color: #ffb5bb;
        }
      }

      &:after {
        content: '';
        position: absolute;
        top: 11px;
        left: calc(50% + 30px);
        right: -30px;
        height: 1px;
        background-color: #f1f1f1;
      }

      &:last-child {
        &:after {
          height: 0;
        }
      }
    }
  }

  .ivv-content {
    position: absolute;
    top: 80px;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #f8f8f8;
    /*background-color: #eeeeee;*/

    .apply-content {
      padding-bottom: 40px;

      .van-cell-group {
        margin-top: 10px;

        /deep/ .van-cell {
          .van-cell__title {
            color: #999;
          }

          .van-cell__value {
            color: #666;

            .van-field__button {
              .van-button {
                height: 22px;
                border-radius: 22px;
              }
            }
          }

          .van-field__value {
            .van-field__control:disabled {
              color: #333;
              -webkit-text-fill-color: #333;
            }
          }

          &.phone-area {
            .van-cell__value {
              .van-field__button {
                .van-button {
                  border-color: transparent;

                  .van-icon {
                    display: inline-block;
                    vertical-align: top;
                  }
                }
              }
            }
          }
        }

        /deep/ .van-radio-group {
          .van-cell {
            .van-cell__title {
              color: #666;

              i {
                color: #999;
                font-size: 12px;
                font-style: normal;
              }
            }

            .van-icon-wechat-pay,
            .van-icon-alipay {
              font-size: 26px;
              padding-right: 8px;
            }
          }
        }

        .pad-left {
          padding-left: 50px;
        }

        .ali-count {
          /deep/ .van-field__button {
            color: #999;
            display: flex;
            align-items: center;
            font-size: 12px;

            .van-icon-question {
              font-size: 16px;
            }
          }
        }

        .valid-type {
          .tip {
            color: #999;

            span {
              color: #333;
              padding-left: 6px;
            }
          }

          .btn {
            color: #ff98a0;
            display: inline-block;
            padding: 6px 15px 6px 0;

            .icon-arrow-right {
              color: #ff98a0;
              font-size: 12px;
              position: static;
            }
          }
        }
      }

      .ivv-btn-submit {
        width: calc(100% - 60px);
        margin: 30px auto;
        height: 33px;
        border-radius: 33px;
      }
    }

    .auth-info {
      text-align: center;
      padding: 20% 15% 0;

      >img {
        width: 68px;
      }

      .title {
        color: #666666;
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 10px;
      }

      p {
        color: #999;
        font-size: 12px;
      }

      .ivv-btn-progress {
        position: absolute;
        width: calc(100% - 60px);
        left: 30px;
        right: 30px;
        bottom: 30px;
        height: 33px;
        border-radius: 33px;
      }
    }

    .refund-info {
      .van-cell-group {
        /deep/ .van-cell {
          .van-cell__title {
            color: #999;
          }

          .van-cell__value {
            color: #666;

            .van-field__button {
              .van-button {
                height: 22px;
                border-radius: 22px;
              }
            }
          }

          &.phone-area {
            .van-cell__value {
              .van-field__button {
                .van-button {
                  border-color: transparent;

                  .van-icon {
                    display: inline-block;
                    vertical-align: top;
                  }
                }
              }
            }
          }
        }

        .theme-title {
          background-color: #f8f8f8;

          /deep/ .van-cell__title {
            color: #666666;
            font-weight: bold;
          }

          /deep/ .van-cell__value {
            color: #ff98a0;
            font-size: 12px;
          }
        }
      }

      .van-steps {
        color: #999;
        padding-right: 15px;
        background-color: transparent;

        .van-step {
          padding: 12px 14px;
          margin-top: 15px;
          border-radius: 6px;
          background-color: #fff;

          .title {
            color: #666;
          }

          p {
            line-height: 22px;

            span {
              color: #ffb5bb;
            }
          }

          /deep/ .van-icon-checked {
            color: #ff98a0 !important;
            font-size: 16px;
          }

          /deep/ .van-step__circle {
            width: 10px;
            height: 10px;
            background-color: #ff98a0;
            border: 4px solid #fff;
          }

          &.van-step--process {}
        }
      }
    }
  }

  .cus-popover {
    position: absolute;
    right: 0;
    background-color: #fff;
    z-index: 10;
    color: #FF98A0;
    font-size: 10px;
    padding: 5px 8px;
    border-radius: 3px;
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.12);
  }

  .alipay-layer {
    width: 80%;
    overflow: hidden;
    border-radius: 6px;

    >img {
      width: 100%;
    }

    .van-icon-clear {
      position: absolute;
      top: 10px;
      right: 10px;
      color: #b4b2b2;
      font-size: 20px;
    }
  }

  .step_box {
    margin-left: 15px;
    margin-right: 20px;
    margin-top: 10px;
    min-height: 200px;
    box-sizing: border-box;
    border-left: solid 4px rgb(229, 229, 229);
    padding-bottom: 30px;
    padding-top: 10px;

    .step_item {
      display: flex;
      margin-top: 20px;

      .step_item_circle {
        width: 12px;
        height: 12px;
        background-color: rgb(255, 152, 160);
        border: 4px solid #fff;
        border-radius: 50%;
        margin-right: 8px;
        position: relative;
        right: 12px;
        z-index: 99;
      }

      .step_content {
        flex: 1;
        background-color: #fff;
        box-sizing: border-box;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        padding-left: 10px;
        padding-bottom: 20px;
        padding-top: 10px;
        line-height: 22px;
        position: relative;

        .step_icon {
          position: absolute;
          left: -11px;
          top: 6px;
          width: 12px;
        }

        .title {
          color: #666;
        }

        p {
          color: #969799;
        }
      }
    }
  }
}
</style>